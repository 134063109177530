import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.tsx";
import Quote from '../components/Quote.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Quote mdxType="Quote">GAYS MAKING is the podcast that spotlights members of the LGBTQIA+ Community who make art in all its forms.
      <span>Sit down with us as we talk show about art, queer issues, and everything in between</span></Quote>
    <h3>{`Credits`}</h3>
    <p>{`Hosted by `}<a parentName="p" {...{
        "href": "https://graysonarts.com/"
      }}>{`Grayson Hay`}</a></p>
    <p>{`Theme Music By Curtis Skinner`}</p>
    <h3>{`The Show Name Controversy`}</h3>
    <p>{`I recognize that the name of the show "Gays Making" is not the most inclusive of names. I use the term "Gay" in the
same way others might use the term "Queer". I am not using it to exclude anyone, and I hope that you can see beyond the
name to the wonderful humans I have a chance to speak with and bring to you. <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import { PageProps } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Typography, { Kind } from '../components/Typography';
import Colors from '../components/Colors';

export type PostProps = PageProps & {
  pageContext: {
    frontmatter: { [k: string]: string };
    fields: { [k: string]: string };
    body: string;
  };
};

const Page: React.FC<PostProps> = ({ pageContext, children }: PostProps) => (
  <Layout className={pageContext.frontmatter.className}>
    <SEO title={pageContext.frontmatter.title} />
    <Typography
      kind={Kind.LargestHeader}
      style={{
        color: Colors[pageContext.frontmatter.color],
      }}
    >
      {pageContext.frontmatter.title}
    </Typography>
    {children}
  </Layout>
);

export default Page;

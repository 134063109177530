import React from 'react';
import './Quote.scss';
import Typography, { Kind } from './Typography';

interface Props {
  children: any;
}

const Sponsor = ({ children }: Props) => (
  <div className="quote">
    <Typography kind={Kind.Quote}>{children}</Typography>
  </div>
);

export default Sponsor;
